import axios from "axios";
import { logOutRedirectCall } from '../Components/common/RedirectPathMange'


const showMessage = (msg) => {
  console.log(msg)
}
try{
  const url = window.location.href
  const configUrlArray = url.split('/');
  if(url.indexOf("index.html" ) !== -1  || url.endsWith("/")) configUrlArray.pop();
  let configUrl = configUrlArray.join('/');
  configUrl = configUrl.concat('/config/config.json');
  var a = configUrl.replace(configUrlArray[4]+"/", "")
  // var a = configUrl.replace(configUrlArray[3]+"/", "")
  const response = await axios.get(a);
  localStorage.setItem('config', JSON.stringify(response.data))
}catch(err){
  console.log(err)
}
var configData = JSON.parse(localStorage.getItem('config'))

const axiosClient = axios.create({
  baseURL: configData.API_PATH,
  headers: {
    'api-key': configData.API_KEY,
    'Accept-Language': 'en',
    'Content-Type': 'application/json',
  }
});

// Body Request
axiosClient.interceptors.request.use(function (request) {
  if (localStorage.getItem("token") !== undefined || localStorage.getItem("token") !== null) {
    request.headers['token'] = JSON.stringify(localStorage.getItem("token"), false)
  }
  return request;
});

//Response
axiosClient.interceptors.response.use(function (response) {
    response = response.data
    if (response.code == -1) {
      logOutRedirectCall();
      return;
    }
    if (response.code == 0) {
      showMessage(response.data.message)
    }
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status == 401) {
      logOutRedirectCall()
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

export { axiosClient };