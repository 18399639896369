import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';

export default function UnityRedirect() {
   

    const { unityProvider, sendMessage, addEventListener, removeEventListener } = useUnityContext({
        loaderUrl:  process.env.PUBLIC_URL + "/ThofMultiplayer/Build/ThofMultiplayer.loader.js",
        dataUrl: process.env.PUBLIC_URL + "/ThofMultiplayer/Build/ThofMultiplayer.data.gz",
        frameworkUrl: process.env.PUBLIC_URL + "/ThofMultiplayer/Build/ThofMultiplayer.framework.js.gz",
        codeUrl: process.env.PUBLIC_URL + "/ThofMultiplayer/Build/ThofMultiplayer.wasm.gz",
        webglContextAttributes: {
            preserveDrawingBuffer: true,
          },
    });


        
    const handleDetails = (msg) => {
        console.log("Call Funcation by Unity")
        sentToken();
        }

        useEffect(() => {
            addEventListener("Requesttoken", handleDetails);
            console.log("Listner Add");
            return () => {
               console.log("Listner Remove");
               removeEventListener("Requesttoken", handleDetails);
            };
          }, [addEventListener, removeEventListener, handleDetails]);
      

          function sentToken() {
            sendMessage("sendToken", "checkTempToken");
          }

    // useEffect(() => {
    //     const serializedData = localStorage.getItem('config');

    //     function sendMessageToUnity(event) {
    //       event.source.postMessage(serializedData, event.origin);
    //     }
    
    //     window.addEventListener('requestForToken', sendMessageToUnity);
    
    //     return () => {
    //       window.removeEventListener('requestForToken', sendMessageToUnity);
    //     };
    //   }, []);
    return (
        <div>
             <Unity style={{ "width": "100%", "height": "120%" }} unityProvider={unityProvider} />
        </div>
    )
}
