import React from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { showErrorMessage } from '../common/RedirectPathMange';
import { guestLogin } from '../../api/apiHandler';
import { tagManager } from '../common/RedirectPathMange';
import {isMobile} from 'react-device-detect';

export default function GuestUser() {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        nick_name: Yup.string()
            .required('Username field is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    var configData = JSON.parse(localStorage.getItem('config'))
    const onSubmit = (data) => {
        tagManager('JoinAsGuest','','')
        guestLogin(data).then((response) => {
            if (response.code == 1) {
                if(isMobile){
                    localStorage.setItem('token', response.data.token)
                    setTimeout(() => {
                        window.location.href = `${configData.UNITY_MOBILE_URL}?${localStorage.getItem('token')}`;
                    }, 500);
                }
                else{
                    localStorage.setItem('token', response.data.token)
                    setTimeout(() => {
                        window.location.href = `${configData.UNITY_URL}?${localStorage.getItem('token')}`;
                    }, 500);
                }
            } else {
                showErrorMessage(response.message)
            }
        })
    }
    return (
        <main class="ithop ">
            <section class="signin_ground">
                <div class="signup_container signin_container container">
                    <div class="row align-items-center height_md_100">
                        <div class="col-8 col-xl-6 col-lg-7 col-md-8 col-sm-9 padding_left_md_0 green_h_md">
                            <div class="green_box green_h_md">
                                <div class="signin_heading">
                                    <div class="signin_heading_text">
                                        <p class="mb-0 text-uppercase signin_heading_text_1 font_700 white_ff font_24">ITHF Metaverse</p>
                                        <span class="text-uppercase signin_heading_text_2 font_24 font_300">guest log in</span> </div>
                                </div>
                                <form class="green_box_form" onSubmit={handleSubmit(onSubmit)}>
                                    <div class="password_input green_box_form_input">
                                        <label class="form-label font_18 font_300 white_ff">Username</label>
                                        <input type="text" class="form-control font_18 font_300" {...register('nick_name')} placeholder="" />
                                        <div className="invalid-feedbacks">{errors.nick_name?.message}</div>
                                    </div>
                                    <div class="password_sign_in in_up_btn">
                                        <button type='submit' class="signin_btn text-uppercase font_20 font_700">Sign in</button>
                                    </div>
                                </form>
                                <Link to='/' class="forgot_back white_ff font_20 font_300 d-flex align-items-center"> <img src="assets/images/icons/back-arrow.svg" class="img-fluid" alt="back" /> Back </Link> </div>
                        </div>
                    </div>
                    <div class="powered_img"> <img src={process.env.PUBLIC_URL + "/assets/images/icons/infosys.svg"} class="img-fluid" alt="image" /> </div>
                </div>
            </section>
        </main>
    )
}
