import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { showMessage, showErrorMessage, loginStoreData, tagManager } from '../common/RedirectPathMange';
import { createUser, CountryList } from '../../api/apiHandler';

export default function Signup() {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        display_name: Yup.string()
            .required('Display name field is required')
            .matches('^[a-zA-Z0-9]', 'Only alphabets & numbers are allowed for this field'),
        full_name: Yup.string()
            .required('Full name field is required')
            .matches('^[a-zA-Z]', 'Only alphabets & numbers are allowed for this field'),
        nationality: Yup.string()
            .required('Nationality field is required'),
        email: Yup.string()
            .required('Email field is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$', "Password must contain an alphabets,numbers and special characters"),
        confirm_password: Yup.string()
            .required('Confirm password is required')
            .min(6, 'Confirm Password must be at least 6 characters')
            .oneOf([Yup.ref('password'), null], 'Password must match'),
        // check_box: Yup.boolean()
        //     .oneOf([true], 'Please check privacy policy')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const [countryList, setCountryList] = useState([])
    let [passwordType, setPasswordType] = useState("password");
    let [confirmPasswordType, setConfirmPasswordType] = useState("password");
    let date = new Date().toLocaleTimeString();

    const onTypeClick = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else { setPasswordType("password") }
    }
    const onTypeClick1 = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
        } else { setConfirmPasswordType("password") }
    }
    const onSubmit = (data) => {
       
        var newData = {
            "name": data.full_name,
            "nick_name": data.display_name,
            "country": data.nationality,
            "email": data.email,
            "password": data.password,
            "c_password": data.confirm_password,
            "user_type": "USER",
            "device_type": "W",
            "signup_type": "N",
            "is_receive_news_updates":"1"
        }
        createUser(newData).then((response) => {
            if (response.code == 1) {
                tagManager('UserCountry','country',data.nationality)
                tagManager('Leaving: signup','time',date)
                tagManager('signup:complete','','')
                localStorage.setItem('email', response.data.email)
                navigate('/otp')
                showMessage(response.message)
            } else {
                showErrorMessage(response.message)
            }
        })
    }
    useEffect(() => {
        tagManager('Init: signup','time',date)
        tagManager('signup:started','','')
        CountryList().then((response) => {
            if (response.code == 1) {
                setCountryList(response.data)
            } else {
                showErrorMessage(response.message)
            }
        })
    }, [])
    const backLogin = () =>{
        tagManager('signup:back','','')
        navigate('/')
    }
    return (
        <>
            <Helmet>
                <title>ITHOF - Signup</title>
            </Helmet>
            <main className="ithop">
                <section className="signin_ground section-signup">
                    <div className="signup_container signin_container container">
                        <div className="row align-items-center height_md_100">
                            <div className="col-8 col-xl-6 col-lg-7 col-md-8 col-sm-9 padding_left_md_0 green_h_md">
                                <div className="green_box green_h_md">
                                    <div className="signin_heading">
                                        <div className="signin_heading_text">
                                            <p className="mb-0 text-uppercase signin_heading_text_1 font_700 white_ff font_24">ITHF Metaverse</p>
                                            <span className="text-uppercase signin_heading_text_2 font_24 font_300">sign up</span> </div>
                                    </div>
                                    <form className="green_box_form needs-validation" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="green_box_form_input">
                                                    <label className="form-label font_18 font_300 white_ff">Display Name</label>
                                                    <input type="text" className="form-control font_18 font_300" {...register('display_name')} placeholder="" />
                                                    <div className="invalid-feedbacks">{errors.display_name?.message}</div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="green_box_form_input">
                                                    <label className="form-label font_18 font_300 white_ff">Email</label>
                                                    <input type="email" className="form-control font_18 font_300" {...register('email')} placeholder="" />
                                                    <div className="invalid-feedbacks">{errors.email?.message}</div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="green_box_form_input">
                                                    <label className="form-label font_18 font_300 white_ff">Full Name</label>
                                                    <input type="text" className="form-control font_18 font_300" {...register('full_name')} placeholder="" />
                                                    <div className="invalid-feedbacks">{errors.full_name?.message}</div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="green_box_form_input">
                                                    <label className="form-label font_18 font_300 white_ff">Nationality</label>
                                                    <select className="form-select font_18 font_300" {...register('nationality')} aria-label="Select country">
                                                        {countryList && countryList.map((item, index) => {
                                                            return (
                                                                <option value={item.short_code} defaultValue={item.id}>{item.name}</option>
                                                            )
                                                        })}

                                                    </select>
                                                </div>
                                                <div className="invalid-feedbacks">{errors.nationality?.message}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="green_box_form_input">
                                                    <label className="form-label font_18 font_300 white_ff">Set Password</label>
                                                    <input type={passwordType} className="form-control font_18" {...register('password')} placeholder="" />
                                                    <span className="input-group-text-signup" onClick={() => onTypeClick()}>
                                                        {passwordType == "password" ? <img src={process.env.PUBLIC_URL + "/assets/images/icons/show.svg"} className="img-fluid hidebutton" alt="show" /> : <img src={process.env.PUBLIC_URL + "/assets/images/icons/hide.svg"} className="img-fluid hidebutton" alt="hide"></img>}
                                                    </span>
                                                    <div className="invalid-feedbacks">{errors.password?.message}</div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="green_box_form_input">
                                                    <label className="form-label font_18 font_300 white_ff">Confirm Password</label>
                                                    <input type={confirmPasswordType} className="form-control font_18 font_300" {...register('confirm_password')} placeholder="" />
                                                    <span className="input-group-text-signup" onClick={() => onTypeClick1()}>
                                                        {confirmPasswordType == "password" ? <img src={process.env.PUBLIC_URL + "/assets/images/icons/show.svg"} className="img-fluid hidebutton" alt="show" /> : <img src={process.env.PUBLIC_URL + "/assets/images/icons/hide.svg"} className="img-fluid hidebutton" alt="hide"></img>}
                                                    </span>
                                                    <div className="invalid-feedbacks">{errors.confirm_password?.message}</div>
                                                </div>
                                            </div>
                                            <div className="green_box_form_input green_box_form_three_quarter">
                                                <input className="form-check-input" type="checkbox" defaultValue="" />
                                                <label className="form-check-label font_18 font_300 white_ff"> I wish to receive news and updates from International Tennis Hall of Fame</label>
                                                <div className="invalid-feedbacks">{errors.check_box?.message}</div>
                                            </div>
                                            <div className="in_up_btn d-flex align-items-center">
                                                <button type='submit' className="signin_btn cursor-pointer text-uppercase font_20 font_700">sign up</button>
                                                <Link to='https://www.tennisfame.com/privacy-policy' target="_blank" className="white_ff font_18 font_300"><u>Terms & Conditions</u></Link>
                                            </div>
                                        </div>
                                    </form>
                                    <a onClick={backLogin} className="forgot_back white_ff font_20 font_300 d-flex align-items-center"> <img src="assets/images/icons/back-arrow.svg" className="img-fluid" alt="back" /> Back </a> </div>
                            </div>
                        </div>
                        <div className="powered_img"> <img src={process.env.PUBLIC_URL + "/assets/images/icons/infosys.svg"} className="img-fluid" alt="image" /> </div>
                    </div>
                </section>
            </main>
        </>
    )
}
