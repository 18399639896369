import React,{useState} from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { showMessage, showErrorMessage } from '../common/RedirectPathMange';
import { resetPassword } from '../../api/apiHandler';


export default function ResetPassword() {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$', "Password must contain an alphabets,numbers and special characters"),
    c_password: Yup.string()
      .required('Confirm password is required')
      .min(6, 'Password must be at least 6 characters')
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$', "Password must contain an alphabets,numbers and special characters"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  let [passwordType,setPasswordType] = useState("password");
  let [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const onTypeClick = () => {
    if (passwordType === "password") {
      setPasswordType("text")
    } else { setPasswordType("password") }
  }
  const onTypeClick1 = () => {
    if (confirmPasswordType === "password") {
        setConfirmPasswordType("text")
    } else { setConfirmPasswordType("password") }
}
  const onSubmit = (data) => {
    data.email = localStorage.getItem('email')
    console.log(data, 'data')
    resetPassword(data).then((response) => {
      if (response.code == 1) {
        navigate('/')
        showMessage(response.message)
      } else {
        showErrorMessage(response.message)
      }
    })
  }

  return (
    <div>
      <main className="ithop">
        <section className="signin_ground">
          <div className="signup_container signin_container container">
            <div className="row align-items-center height_md_100">
              <div className="col-8 col-xl-6 col-lg-7 col-md-8 col-sm-9 padding_left_md_0 green_h_md">
                <div className="green_box green_h_md">
                  <div className="signin_heading">
                    <div className="signin_heading_text">
                      <p className="mb-0 text-uppercase signin_heading_text_1 font_700 white_ff font_24">ITHF Metaverse</p>
                      <span className="text-uppercase signin_heading_text_2 font_24 font_300">forgot password</span> </div>
                  </div>
                  <form className="green_box_form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="green_box_form_input">
                      <label className="form-label font_18 font_300 white_ff">Set Password</label>
                      <input type={passwordType} className="form-control font_18 font_300" {...register('password')} placeholder="" />
                      <span className="input-group-text" onClick={() => onTypeClick()}>
                        {passwordType == "password" ? <img src={process.env.PUBLIC_URL + "/assets/images/icons/show.svg"} className="img-fluid hidebutton" alt="show" /> : <img src={process.env.PUBLIC_URL + "/assets/images/icons/hide.svg"} className="img-fluid hidebutton" alt="hide"></img>}
                      </span>
                      <div className="invalid-feedbacks">{errors.password?.message}</div>
                    </div>
                    <div className="green_box_form_input">
                      <label className="form-label font_18 font_300 white_ff">Confirm Password</label>
                      <input type={confirmPasswordType} className="form-control font_18 font_300" {...register('c_password')} placeholder="" />
                      <span className="input-group-text" onClick={() => onTypeClick1()}>
                        {confirmPasswordType == "password" ? <img src={process.env.PUBLIC_URL + "/assets/images/icons/show.svg"} className="img-fluid hidebutton" alt="show" /> : <img src={process.env.PUBLIC_URL + "/assets/images/icons/hide.svg"} className="img-fluid hidebutton" alt="hide"></img>}
                      </span>
                      <div className="invalid-feedbacks">{errors.c_password?.message}</div>
                    </div>
                    <div className="password_sign_in in_up_btn">
                      <button type='submit' className="signin_btn text-uppercase font_20 font_700">set password</button>
                    </div>
                  </form>
                  <Link to='/' className="forgot_back white_ff font_20 font_300 d-flex align-items-center"> <img src="assets/images/icons/back-arrow.svg" className="img-fluid" alt="back" /> Back </Link> </div>
              </div>
            </div>
            <div className="powered_img"> <img src={process.env.PUBLIC_URL + "/assets/images/icons/infosys.svg"} className="img-fluid" alt="image" /> </div>
          </div>
        </section>
      </main>
    </div>
  )
}
