import Swal from 'sweetalert2'
import React,{useState,useEffect} from 'react';
import axios from 'axios';

export function logOutRedirectCall() {
  localStorage.removeItem("token");
}


export function loginStoreData(data) {
  localStorage.setItem("token", data.token);
}
export const showMessage = (msg) => {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500
  })
}
export const showErrorMessage = (msg) => {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500
  })
}

export const tagManager = (event,paramName,ParamValue) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
      event: event,
      parameter:paramName,
      value:ParamValue
    })
}
