import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { showMessage, showErrorMessage } from '../common/RedirectPathMange';
import { verifyOtp, sendOtp } from '../../api/apiHandler';

export default function ForgotOtp() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    otp1: Yup.string()
      .required('OTP field is required'),
    otp2: Yup.string()
      .required('OTP field is required'),
    otp3: Yup.string()
      .required('OTP field is required'),
    otp4: Yup.string()
      .required('OTP field is required'),
    otp5: Yup.string()
      .required('OTP field is required'),
    otp6: Yup.string()
      .required('OTP field is required'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;


  const onSubmit = (data) => {
    data.email = localStorage.getItem('email')
    data.is_verify_signup = "1"
    var arr = []
    arr.push(data.otp1)
    arr.push(data.otp2)
    arr.push(data.otp3)
    arr.push(data.otp4)
    arr.push(data.otp5)
    arr.push(data.otp6)
    var str = arr.join('');
    data.otp = str
    console.log(data, 'data')
    verifyOtp(data).then((response) => {
      if (response.code == 1) {
        navigate('/reset-password')
        showMessage(response.message)
      } else {
        showErrorMessage(response.message)
      }
    })
  }
  const resendOTP = () => {
   
    var data = {
      email: localStorage.getItem('email'),
      is_verify_signup: '1'
    }
    sendOtp(data).then((response) => {
      if (response.code == 1) {
        reset()
        showMessage(response.message)
      } else {
        showErrorMessage(response.message)
      }
    })
  }
  const email = localStorage.getItem('email');
  const parts = email.split('@');
  const username = parts[1];
  return (
    <main className="ithop ">
      <Helmet>
        <script>
          {
            `
            var otpInputs = document.querySelectorAll('.otp-input');
            otpInputs.forEach((input, index) => {
            var reg = /^\d+$/;
           input.addEventListener('input', (e) => {
             if (index < otpInputs.length - 1 && e.target.value.length === 1) {
            otpInputs[index + 1].focus();
           }
         });

      input.addEventListener('keydown', (e) => {
        var reg = /^\d+$/;
        if (e.key === 'Backspace' && index > 0 && e.target.value.length === 0) {
          otpInputs[index - 1].focus();
        }
      });
    });
            `
          }
        </script>
      </Helmet>
      <section className="signin_ground">
        <div className="signup_container signin_container container">
          <div className="row align-items-center height_md_100">
            <div className="col-8 col-xl-6 col-lg-7 col-md-8 col-sm-9 padding_left_md_0 green_h_md">
              <div className="green_box green_h_md">
                <div className="signin_heading">
                  <div className="signin_heading_text">
                    <p className="mb-0 text-uppercase signin_heading_text_1 font_700 white_ff font_24 font_300">ITHF Metaverse</p>
                    <span className="text-uppercase signin_heading_text_2 font_24 font_300">Verify it's you</span> </div>
                </div>
                <form className="green_box_form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="green_box_form_input">
                    <p className="signin_subheading_text_1 signin_heading_text_2 mb-0 font_20 font_300">Security code sent to <span>***@{username}</span></p>
                    <div className="height-100 d-flex justify-content-center align-items-center">
                      <div className="position-relative">
                        <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                          <input className="m-2 otp-input text-center form-control font_18 font_300" type="text" id="first" maxLength="1" {...register('otp1')} />

                          <input className="m-2 otp-input text-center form-control font_18 font_300" type="text" id="second" maxLength="1" {...register('otp2')} />
                          <input className="m-2 otp-input text-center form-control font_18 font_300" type="text" id="third" maxLength="1" {...register('otp3')} />
                          <input className="m-2 otp-input text-center form-control font_18 font_300" type="text" id="fourth" maxLength="1" {...register('otp4')} />
                          <input className="m-2 otp-input text-center form-control font_18 font_300" type="text" id="fifth" maxLength="1" {...register('otp5')} />
                          <input className="m-2 otp-input text-center form-control font_18 font_300" type="text" id="sixth" maxLength="1" {...register('otp6')} />
                          {/* <OTPInput className="otp_input signin_input" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure /> */}
                        </div>
                        <div className="invalid-feedbacks">{errors.otp6?.message}</div>
                        {/* <div className="invalid-feedbacks">{errors.otp2?.message}</div>
                        <div className="invalid-feedbacks">{errors.otp3?.message}</div>
                        <div className="invalid-feedbacks">{errors.otp4?.message}</div>
                        <div className="invalid-feedbacks">{errors.otp5?.message}</div>
                        <div className="invalid-feedbacks">{errors.otp6?.message}</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="in_up_btn d-flex align-items-center">
                    <button type='submit' className="signin_btn text-uppercase font_20 font_700">Contine</button>
                    <Link onClick={resendOTP} className="white_ff font_18 font_300"><u>Resend</u></Link>
                  </div>
                  {/* <ResendOTP className="violet_4b cursor-pointer" onResendClick={resendOTP} /> */}
                </form>
                <Link to='/forgot-password' className="forgot_back white_ff font_20 font_300 d-flex align-items-center"> <img src="assets/images/icons/back-arrow.svg" className="img-fluid" alt="back" /> Back </Link> </div>
            </div>
          </div>
          <div className="powered_img"> <img src="assets/images/icons/infosys.svg" className="img-fluid" alt="image" /> </div>
        </div>
      </section>
    </main>
  )
}
