import React from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { showErrorMessage,showMessage } from '../common/RedirectPathMange';
import { forgotPassword } from '../../api/apiHandler';

export default function ForgotPassword() {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email field is required')
            .email('Email address is invalid'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        forgotPassword(data).then((response)=>{
            if(response.code == 1){
                localStorage.setItem('email',data.email)
                navigate('/verify-otp')
                showMessage(response.message)
            }else{
                showErrorMessage(response.message)
            }
        })
    }

    return (
        <main className="ithop ">
            <section className="signin_ground">
                <div className="signup_container signin_container container">
                    <div className="row align-items-center height_md_100">
                        <div className="col-8 col-xl-6 col-lg-7 col-md-8 col-sm-9 padding_left_md_0 green_h_md">
                            <div className="green_box green_h_md">
                                <div className="signin_heading">
                                    <div className="signin_heading_text">
                                        <p className="mb-0 text-uppercase signin_heading_text_1 font_700 white_ff font_24 font_300">ITHF Metaverse</p>
                                        <span className="text-uppercase signin_heading_text_2 font_24 font_300">forgot password</span>
                                        <p className="signin_subheading_text_1 mb-0 font_20 font_300 white_ff">Provide your account email address and we will send you a link to reset your password.</p>
                                    </div>
                                </div>
                                <form className="green_box_form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="green_box_form_input">
                                        <label className="form-label font_18 font_300 white_ff">Email address</label>
                                        <input type="email" className="form-control font_18 font_300" {...register('email')} placeholder="" />
                                        <div className="invalid-feedbacks">{errors.email?.message}</div>
                                    </div>
                                <div className="password_sign_in in_up_btn"> 
                                <button type='submit' className="signin_btn text-uppercase font_20 font_700">Send link</button> 
                                </div>
                                </form>
                                <Link to='/' className="forgot_back white_ff font_20 font_300 d-flex align-items-center"> 
                                <img src="assets/images/icons/back-arrow.svg" className="img-fluid" alt="back" /> Back 
                                </Link> </div>
                        </div>
                    </div>
                    <div className="powered_img"> <img src={process.env.PUBLIC_URL + "/assets/images/icons/infosys.svg"} className="img-fluid" alt="image" /> </div>
                </div>
            </section>
        </main>
    )
}
