import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { showMessage, showErrorMessage, tagManager } from '../common/RedirectPathMange';
import { loginUser, CheckUserToken } from '../../api/apiHandler';
import {isMobile} from 'react-device-detect';

export default function Login() {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email field is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$', "Password must contain an alphabets,numbers and special characters"),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    let [passwordType, setPasswordType] = useState("password");

    useEffect(() => {
        tagManager('Init:Login','','')
        CheckUserToken({ "token": localStorage.getItem('token') }).then((response) => {
            if (response.code == 1) {
                if(isMobile){
                    window.location.href = `${configData.UNITY_MOBILE_URL}?${localStorage.getItem('token')}`;
                }else{
                    window.location.href = `${configData.UNITY_URL}?${localStorage.getItem('token')}`;
                }
            }
        })
    }, [])

    var configData = JSON.parse(localStorage.getItem('config')) 
    const onSubmit = (data) => {
        data.user_type = "USER"
        loginUser(data).then((response) => {
            console.log(response)
            if (response.code == 1) {
                if(isMobile){
                    localStorage.setItem('token', response.data.token)
                    tagManager('login','','')
                    setTimeout(() => {
                        window.location.href = `${configData.UNITY_MOBILE_URL}?${localStorage.getItem('token')}`;
                    }, 500);
                }
                else{
                    localStorage.setItem('token', response.data.token)
                    tagManager('login','','')
                    setTimeout(() => {
                        window.location.href = `${configData.UNITY_URL}?${localStorage.getItem('token')}`;
                    }, 500);
                }
            } else {
                showErrorMessage(response.message)
            }
        })
    }
    const onTypeClick = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else { setPasswordType("password") }
    }
    return (
        <div>
            <main className="ithop ">
                <section className="signin_ground">
                    <div className="signup_container signin_container container">
                        <div className="row align-items-center height_md_100">
                            <div className="col-8 col-xl-6 col-lg-7 col-md-8 col-sm-9 padding_left_md_0 green_h_md">
                                <div className="green_box green_h_md">
                                    <div className="signin_heading d-flex justify-content-between align-items-center">
                                        <div className="signin_heading_image d-flex align-items-center">
                                            <div className="signin_heading_tennis_image"> <img src={process.env.PUBLIC_URL + "/assets/images/icons/img-logo-ithof.svg"} className="img-fluid" alt="image" /> </div>
                                        </div>
                                        <div className="signin_heading_text">
                                            <p className="mb-0 text-uppercase signin_heading_text_1 font_700 white_ff font_24">Metaverse</p>
                                            <span className="text-uppercase signin_heading_text_2 font_24 font_300">Sign in</span> </div>
                                    </div>
                                    <form className="green_box_form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="green_box_form_input">
                                            <label className="form-label font_18 font_300 white_ff">Email address</label>
                                            <input type="email" className="form-control font_18 font_300" {...register('email')} placeholder="" />
                                            <div className="invalid-feedbacks">{errors.email?.message}</div>
                                        </div>
                                        <div className="green_box_form_input">
                                            <label className="form-label font_18 font_300 white_ff">Password</label>
                                            <input type={passwordType} className="form-control font_18 font_300" {...register('password')} placeholder="" />
                                            <span className="input-group-text" onClick={() => onTypeClick()}>
                                                {passwordType == "password" ? <img src={process.env.PUBLIC_URL + "/assets/images/icons/show.svg"} className="img-fluid hidebutton" alt="show" /> : <img src={process.env.PUBLIC_URL + "/assets/images/icons/hide.svg"} className="img-fluid hidebutton" alt="hide"></img>}
                                            </span>
                                            <div className="invalid-feedbacks">{errors.password?.message}</div>
                                        </div>
                                        <div className="in_up_btn d-flex align-items-center">
                                            <button type='submit' className="signin_btn text-uppercase font_20 font_700">Sign in</button>
                                            <Link to='signup' className="white_ff font_18 font_300"><u>Sign up</u></Link>
                                        </div>
                                    </form>
                                    <div className="forgot_text d-flex justify-content-between align-items-center"> <Link to='forgot-password' className="white_ff font_18 font_300"><u>Forgot your password?</u></Link> <Link to='guest-user' className="white_ff font_18 font_300"><u>Join as a guest</u></Link> </div>
                                </div>
                            </div>
                        </div>
                        <div className="powered_img"> <img src={process.env.PUBLIC_URL + "/assets/images/icons/infosys.svg"} className="img-fluid" alt="image" /> </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
