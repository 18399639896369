import React from 'react'
import { Navigate,Outlet,useLocation } from 'react-router-dom'

export default function PrivateComponent() {
    let location = useLocation()
    let pathLocation = location.pathname

    const auth = localStorage.getItem('token');
    return auth ? <Outlet /> : <Navigate to="/" />
}
