import { axiosClient } from "./apiClient";

export function CountryList(data){
    return axiosClient.get('user/get-countries', data);
}
export function createUser(data){
    return axiosClient.post('user/signup', data);
}
export function sendOtp(data){
    return axiosClient.post('user/send-otp', data);
}
export function verifyOtp(data){
    return axiosClient.post('user/verify-otp', data);
}
export function loginUser(data){
    return axiosClient.post('user/login', data);
}
export function forgotPassword(data){
    return axiosClient.post('user/forgot-password', data);
}
export function resetPassword(data){
    return axiosClient.post('user/reset-password', data);
}
export function guestLogin(data){
    return axiosClient.post('user/guest-signup', data);
}
export function CheckUserToken(data){
    return axiosClient.post('user/check-guest-user-loggedout', data);
}


